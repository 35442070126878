import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from "../../.."
import RubbleIcon from "../../../components/icons/RubbleIcon"
import { ApiService } from "../../../core/api/api"
import { useFetching } from "../../../core/hooks/useFetching"
import { XProduct } from "../../../core/models/store/product"
import { declOfNum } from "../../../core/utils/utils"

import { DatePicker, message, Radio, Space, Switch, TimePicker } from 'antd'
import dayjs from 'dayjs'
import moment from "moment"
import customParseFormat from 'dayjs/plugin/customParseFormat'

import InputMask from 'react-input-mask'

import styles from "./BasketView.module.scss"
import { ruPickerLang, StorageKeys } from "../../../core/utils/defaults"
import XFlexed from "../XForm/XFlexed"
import { useNavigate } from "react-router-dom"
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"
import OptionsModal from "../OptionsModal/OptionsModal"
import {getCompound} from "../../../core/utils/store_utils"

dayjs.extend(customParseFormat)

const dateFormat = 'YYYY-MM-DD'

interface XBasket {
  id: number
  product_id: number
  user_id: number
  product: any
  amount: number
  status: number
}

interface BasketViewProps {
  onOpenProduct: (product: XProduct) => void
  onOpenCategory: (category: string) => void
}

const BasketView: FC<BasketViewProps> = ({
  onOpenProduct, onOpenCategory
}) => {

  const { store, shopStore } = useContext(Context)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()

  const [openned, setOpenned] = useState(false)
  const [preOpenned, setPreOpenned] = useState(false)
  
  const [isCommentVisible, setIsCommentVisible] = useState(false)
  const [isAddressVisible, setIsAddressVisible] = useState(false)
  const [isPromoVisible, setIsPromoVisible] = useState(false)
  const [isPromoLoading, setIsPromoLoading] = useState(false)
  const [isShopVisible, setIsShopVisible] = useState(false)
  const [isAccountVisible, setIsAccountVisible] = useState(false)
  
  const [deliveryAddress, setDeliveryAddress] = useState("")
  const [deliveryStreet, setDeliveryStreet] = useState("")
  const [deliveryCity, setDeliveryCity] = useState("")
  const [deliveryHouse, setDeliveryHouse] = useState("")
  const [deliveryFloor, setDeliveryFloor] = useState("")
  const [deliveryEntrance, setDeliveryEntrance] = useState("")
  const [deliveryRoom, setDeliveryRoom] = useState("")
  const [deliveryKey, setDeliveryKey] = useState("")

  const [clientName, setClientName] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientEmail, setClientEmail] = useState("")

  const [deliveryComment, setDeliveryComment] = useState("")
  const [deliveryShop, setDeliveryShop] = useState("")
  const [deliveryDate, setDeliveryDate] = useState(dayjs(moment().add("+1", "days").format("YYYY-MM-DD"), dateFormat))
  const [deliveryOwn, setDeliveryOwn] = useState(false)
  
  const [deliveryTime, setDeliveryTime] = useState("10:00")
  const [timeOpts, setTimeOpts] = useState([] as any[])

  const [payMethod, setPayMethod] = useState("Картой или наличными при получении")
  const [payMethodP, setPayMethodP] = useState("qr")
  
  // const [goods, setGoods] = useState([] as XBasket[])

  const [restoreCart] = useFetching(async () => {
    const goodsRes = localStorage.getItem("fermstory_goods_offline")

    shopStore.setOrders([])

    if (goodsRes == null) {
      shopStore.setGoods([])
      return
    }

    shopStore.setGoods(JSON.parse(goodsRes))

    const now = moment()
    const defDate = dayjs(now.format("YYYY-MM-DD"), dateFormat)
    setDeliveryDate(defDate)
    setDeliveryTime(shopStore.timezones[0].zone)

    if (shopStore.selectedPromocode != null && shopStore.selectedPromocode.by_price != 0) {
      let sum = 0
      shopStore.goods.forEach(good => {
        sum += (good.amount * (getFinalPrice(good.product) - ((good.product.price / 100) * good.product.sale)))
      })
      if (sum < shopStore.selectedPromocode.by_price) {
        message.warning(`Промокод отменен! Cумма заказа должна быть больше ${shopStore.selectedPromocode.by_price} руб.!`)
        shopStore.setSelectedPromocode(null)
        shopStore.setPromocode("")
      }
    }
  })

  const [fetchCart] = useFetching(async () => {
    const resOrders = await ApiService.store.getOrders()
    const res = await ApiService.store.getBasket()

    console.log(res, resOrders)

    shopStore.setGoods(res.data.goods)
    shopStore.setOrders(resOrders.data.orders)

    const now = moment()
    const defDate = dayjs(now.add("+1", "days").format("YYYY-MM-DD"), dateFormat)
    setDeliveryDate(defDate)

    setDeliveryTime(shopStore.timezones[0].zone)

    if (shopStore.selectedPromocode != null && shopStore.selectedPromocode.by_price != 0) {
      let sum = 0
      shopStore.goods.forEach(good => {
        sum += (good.amount * (getFinalPrice(good.product) - ((good.product.price / 100) * good.product.sale)))
      })
      if (sum < shopStore.selectedPromocode.by_price) {
        message.warning(`Промокод отменен! Cумма заказа должна быть больше ${shopStore.selectedPromocode.by_price} руб.!`)
        shopStore.setSelectedPromocode(null)
        shopStore.setPromocode("")
      }
    }
  })

  const getTotalSum = () => {
    let sum = 0
    shopStore.goods.forEach(good => {
      sum += (good.amount * getFinalPrice(good.product))
    })
    return sum
  }

  const getTotalSale = () => {
    let sum = 0
    shopStore.goods.forEach(good => {
      sum += good.amount * ((getFinalPrice(good.product) / 100) * good.product.sale)
    })
    return sum
  }

  const getTotal = () => {
    let sum = 0
    shopStore.goods.forEach(good => {
      sum += (good.amount * (getFinalPrice(good.product) - ((getFinalPrice(good.product) / 100) * good.product.sale)))
    })
    if (shopStore.selectedPromocode != null) {
      if (shopStore.selectedPromocode.sale == 0) {
        sum = sum - ((sum / 100) * shopStore.selectedPromocode.sale_procent)
      } else {
        sum = sum - shopStore.selectedPromocode.sale
      }
    }
    if (!deliveryOwn && getTotalSum() < 5000) {
      sum += 500
    }
    return sum
  }

  const getPromoTotal = () => {
    if (shopStore.selectedPromocode != null) {
      if (shopStore.selectedPromocode.sale == 0) {
        let sum = 0
        shopStore.goods.forEach(good => {
          sum += (good.amount * (getFinalPrice(good.product) - ((getFinalPrice(good.product) / 100) * good.product.sale)))
        })
        if (!deliveryOwn && sum < 5000) {
          sum += 500
        }
        return ((sum) / 100) * shopStore.selectedPromocode.sale_procent
      } else {
        return shopStore.selectedPromocode.sale
      }
    } else {
      return 0
    }
  }

  const getFinalPrice = (product: XProduct) => {
    if (product.compound == "item") {
      return product.price
    } else {
      return product.price / 10
    }
  }

  const openView = () => {
    setPreOpenned(prev => prev = true)
    setTimeout(() => {
      setOpenned(prev => prev = true)
    }, 100)
  }

  const closeView = () => {
    setOpenned(prev => prev = false)
    setTimeout(() => {
      setPreOpenned(prev => prev = false)
    }, 300)
  }

  const amountChange = async (product: XProduct, type: number, amount: number) => {
    if (product != null) {
      const exists = shopStore.goods.find(v => v.product.id == product.id)
      if (exists != undefined) {
        if (type == 0 && exists.amount == 1) {
          if (store.isAuth) {
            const res = await ApiService.store.updateBasket({
              action: "delete",
              id: exists.id
            })
  
            console.log(res)
          } else {
            let lst: any[] = shopStore.goods.filter(v => v.product_id != product.id)
            localStorage.setItem("fermstory_goods_offline", JSON.stringify(lst))
            shopStore.setGoods(lst)
          }

          message.success("Товар удален из корзины!")
        } else {
          if (store.isAuth) {
            const res = await ApiService.store.updateBasket({
              action: "update",
              data: {
                amount: type == 0 ? exists.amount - amount : exists.amount + amount,
              },
              id: exists.id
            })
  
            console.log(res)
          } else {
            let lst: any[] = []

            shopStore.goods.forEach(good => {
              let gd: any = {...good}
              if (good.product_id == product.id) {
                gd.amount = type == 0 ? exists.amount - amount : exists.amount + amount
              }
              lst.push(gd)
            })
            
            localStorage.setItem("fermstory_goods_offline", JSON.stringify(lst))
            
            shopStore.setGoods(lst)
          }
        }

        if (store.isAuth) {
          fetchCart()
        } else {
          restoreCart()
        }
      }
    }
  }

  const fixNum = (num: number) => {
    return num.toFixed(2)
  }

  const createAccountAndOrder = async () => {
    if (clientName == "") {
      return message.warning("Введите ваше имя!")
    }

    if (clientPhone == "") {
      return message.warning("Введите ваш телефон!")
    }

    if (clientEmail == "") {
      return message.warning("Введите ваш email!")
    }

    if (!clientEmail.includes("@")) {
      return message.warning("Введите корректный email!")
    }

    /**
     * 1) Новый метод -> /users/auth/threat
     * 1.1) Отправляем имя+телефон+мейл
     * 1.2) Если такой пользователь уже существует, возвращаем его данные краткие, и создаем на него аккаунт, после этого кидаем клиента на авторизацию
     * 1.3) Если не существует, создаем нового пользователя, в письме отправляем пароль
     * 1.3.1) После аккаунта, создаем заказ на нового пользователя
     */

    setIsAccountVisible(false)

    let phone = clientPhone.replaceAll("+", "").replaceAll("-", "").replaceAll(" ", "")

    const signRes = await ApiService.users.signUpThreat({
      login: phone,
      phone: phone,
      email: clientEmail,
      name: clientName
    })

    store.setToken(signRes.data.token)
    store.setUserData(signRes.data.user)

    localStorage.setItem(StorageKeys.TOKEN, signRes.data.token)

    createOrderAction(signRes.data.user.id)
  }

  const createOrder = async () => {
    if (shopStore.goods.length == 0) {
      message.warning("Для оформления заказа, в корзине должен быть хотя бы один товар!")
      return
    }

    if (!deliveryOwn && deliveryAddress == "") {
      message.warning("Перед оформлением - заполните адрес доставки!")
      return
    }

    if (deliveryOwn && deliveryShop == "") {
      message.warning("Перед оформлением - выберите магазин получения заказа!")
      return
    }

    if (!store.isAuth) {
      setIsAccountVisible(true)
      return
    }

    createOrderAction()
  }

  const createOrderAction = async (user_id: number = 0) => {
    let allPrice = 0, allSale = 0, allPromo = 0
    let prod_ids: number[] = [], amounts: number[] = []

    shopStore.goods.forEach((good: any) => {
      prod_ids.push(good.product.id)
      amounts.push(good.amount)
      allPrice += good.amount * getFinalPrice(good.product) 
      allSale += good.amount * ((getFinalPrice(good.product) / 100) * good.product.sale)
    })

    if (shopStore.selectedPromocode != null) {
      /**
       * 1) Check first_order rule
       * 2) Check by_price rule
       * 3) Check multiuse rule
       * 4) If all rules are positive:
       * 4.1) If sale field not 0 -> save promo_sale = promo
       * 4.2) If sale field is 0 -> save promo_sale = ((allPrice - allSale) / 100) * sale_procent
       */

      if (shopStore.selectedPromocode.sale == 0) {
        allPromo = ((allPrice - allSale) / 100) * shopStore.selectedPromocode.sale_procent
      } else {
        allPromo = shopStore.selectedPromocode.sale
      }
    }

    if (!deliveryOwn && allPrice < 5000) {
      allPrice += 500
    }

    let props: any = {
      product_ids: prod_ids,
      user_id: store.userData.id,
      amounts: amounts,
      price: allPrice,
      sale: allSale,
      delivery_date: deliveryDate.format("DD.MM.YYYY"),
      delivery_time: deliveryTime,
      payment_method: payMethodP == "qr" ? "QR Код" : (payMethodP == "card" ? "Банковской картой" : "Наличными"),
      ship_method: deliveryOwn ? "pickup" : "delivery",
      address: deliveryOwn ? deliveryShop : deliveryAddress,
      comment: deliveryComment,
      promo: allPromo,
      promocode: shopStore.selectedPromocode != null ? shopStore.selectedPromocode.code : "",
      status: 1
    }

    const res = await ApiService.store.createOrder(props)

    console.log(res)

    if (store.isAuth) {
      fetchCart()
    } else {
      localStorage.setItem("fermstory_goods_offline", JSON.stringify([]))
      shopStore.setGoods([])
    }
    
    closeView()

    message.success("Заказ успешно создан!")

    setTimeout(() => {
      // window.location.href = "/account/orders"
    }, 1000)
  }

  useEffect(() => {
    if (store.isAuth) {
      fetchCart()
    } else {
      restoreCart()
    }

    const onScroll = (ev: any) => {
      const scrollTop = ev.target.scrollTop

      const cardwrap = document.getElementById("cardwrap")
      const basketBtn = document.getElementById("basket_btn")

      if (cardwrap != null && basketBtn != null) {
        const offsetCW = document.body.clientHeight - ((cardwrap.getBoundingClientRect().top) + cardwrap.offsetHeight)
        const offsetBB = document.body.clientHeight - ((basketBtn.getBoundingClientRect().top) + basketBtn.offsetHeight)

        console.log(offsetCW, offsetBB)
        if (offsetCW > offsetBB + (width > 768 ? 80 : 150)) {
          shopStore.setIsBasketVisible(false)
        } else {
          shopStore.setIsBasketVisible(true) 
        }
      }
    }

    const root = document.getElementById("root")

    root!.addEventListener("scroll", onScroll)
    return () => root!.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <>
      <div className={styles.basket_ph} style={{
        display: preOpenned ? "block" : "none",
        opacity: openned ? "1" : "0"
      }} onClick={(ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        closeView()
      }}></div>
      
      <div id="basket_btn" className={styles.basket_view + (openned ? " " + styles.openned : "")} style={{
        left: `${shopStore.leftPos}`,
        opacity: shopStore.isBasketVisible ? "1" : "0",
        userSelect: shopStore.isBasketVisible ? "unset" : "none",
        pointerEvents: shopStore.isBasketVisible ? "unset" : "none"
      }}>
        <div className={styles.bv_default} onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()

          // if (store.isAuth) {
            if (!openned) {
              openView()
            } else {
              closeView()
            }
          // } else {
          //   window.location.href = "/signin"
          // }
        }}>
          <div className={styles.bv_default__wrap}>
            <div className={styles.bv_default__count}>{shopStore.goods.length} {declOfNum(shopStore.goods.length, ["товар", "товара", "товаров"])}</div>
            <div className={styles.bv_default__price}>≈ {getTotalSum()} <RubbleIcon /></div>
          </div>
          {/* {store.isAuth ? <div className={styles.bv_default__wrap}>
            <div className={styles.bv_default__count}>{shopStore.goods.length} {declOfNum(shopStore.goods.length, ["товар", "товара", "товаров"])}</div>
            <div className={styles.bv_default__price}>≈ {getTotalSum()} <RubbleIcon /></div>
          </div> : <div className={styles.bv_default__wrap}>
            <div className={styles.bv_default__count}>Авторизуйтесь</div>
            <div className={styles.bv_default__price} style={{opacity: "0.6"}}>Корзина</div>
          </div>} */}
          <div className={styles.bv_default__wrap}>
            <div className={styles.bv_default__count}>Корзина</div>
            <div className={styles.bv_default__close}><i className="fas fa-xmark"></i></div>
          </div>
          {/* <div className={styles.bv_default__time}></div> */}
        </div>
        <div className={styles.bv_openned}>
          {shopStore.goods.length == 0 ? <div className={styles.goods_empty}>Список товаров пуст</div> : <></>}
          <div className={styles.goods}>
            {shopStore.getSortedGoods().map(good => <div className={styles.goods_item}>
              <div className={styles.goods_item__img} style={{backgroundImage: `url(${good.product.images.split(";")[0]})`}}>
                {good.in_favorite ? <span className={styles.liked}><i className="fas fa-heart"></i></span> : <></>}
              </div>
              <div className={styles.goods_item__data}>
                <h2 onClick={() => {
                  closeView()
                  onOpenProduct(good.product)
                }}>{good.product.name}</h2>
                <h3 onClick={() => onOpenCategory(good.product.categories_str)}>{good.product.categories_str}</h3>
              </div>
              <div className={styles.goods_item__price}>
                <span className={good.product.sale > 0 ? styles.sld : ""}>{fixNum(good.amount * getFinalPrice(good.product))} <RubbleIcon /> <small>/ {getCompound(good.product.compound)}</small></span>
                {good.product.sale > 0 ? <span className={styles.saled}>{fixNum(good.amount * (getFinalPrice(good.product) - ((getFinalPrice(good.product) / 100) * good.product.sale)))} <RubbleIcon /></span> : <></>}
              </div>
              <div className={styles.goods_item__counter}>
                <div className={styles.gic_btn} onClick={() => amountChange(good.product, 0, 1)}>-</div>
                <div className={styles.gic_count}>{good.amount}</div>
                <div className={styles.gic_btn} onClick={() => amountChange(good.product, 1, 1)}>+</div>
              </div>
            </div>)}
          </div>
          <div className={styles.details}>
            <div className={styles.details_block}>
              <div className={styles.db_label}>Выберите дату и время доставки</div>
              <div className={styles.db_content}>

              <XFlexed>
                <DatePicker
                  defaultValue={deliveryDate}
                  value={deliveryDate}
                  onChange={(date, dates) => {
                    console.log(date, dates)
                    setDeliveryDate(date)
                  }}
                  locale={{
                    lang: {
                      locale: "ru_RU",
                      placeholder: "Выбрать дату",
                      ...ruPickerLang
                    },
                    timePickerLocale: {}
                  }}
                  minDate={dayjs(moment().add("+1", "days").format("YYYY-MM-DD"), dateFormat)}
                  maxDate={dayjs(moment().add("+1", "month").format("YYYY-MM-DD"), dateFormat)}
                  />

                <div className={styles.timepicker}>
                  {deliveryTime}
                </div>
              </XFlexed>

              <div className={styles.timepicker_opts}>
                {shopStore.timezones.map(timezone => <div className={deliveryTime == timezone.zone ? styles.selected : ""} onClick={() => setDeliveryTime(timezone.zone)}>{timezone.zone}</div>)}
              </div>

              </div>
            </div>

            <div className={styles.details_block} style={{marginTop: "30px"}}>
              <div className={styles.db_label}>Выберите {deliveryOwn ? "магазин" : "адрес"}</div>
              <div className={styles.db_content} style={{position: "relative"}}>

                <div className={styles.switcher}>
                  <div><Switch checked={deliveryOwn} defaultChecked={deliveryOwn} onChange={(ev) => setDeliveryOwn(ev)} /></div>
                  <div>Самовывоз</div>
                </div>

                {!deliveryOwn ? <div className={styles.delivery_info}>
                  <a href="/delivery" target="_blank">Подробнее о доставке</a>
                </div> : <></>}

                <div className={styles.editable} onClick={() => setIsAddressVisible(true)}>
                  <div className={styles.editable_value}>{deliveryOwn ? (deliveryShop == "" ? "Выберите магазин получения" : deliveryShop) : (deliveryAddress == "" ? "Введите адрес доставки" : deliveryAddress)}</div>
                  <div className={styles.editable_action}><i className="far fa-pen"></i></div>
                </div>

              </div>
            </div>
            
            <div className={styles.details_block}>
              {/* <div className={styles.db_label}>Оплата</div> */}
              <div className={styles.db_content}>
                <div className={styles.editable} onClick={() => setIsPromoVisible(true)}>
                  <div className={styles.editable_value}>{shopStore.selectedPromocode != null ? shopStore.selectedPromocode.code : "Промокод"}</div>
                  <div className={styles.editable_action}><i className="far fa-pen"></i></div>
                </div>
              </div>
            </div>

            <div className={styles.details_block}>
              <div className={styles.db_content}>
                <div className={styles.editable} onClick={() => setIsCommentVisible(true)}>
                  <div className={styles.editable_value}>{deliveryComment == "" ? "Укажите комментарий" : deliveryComment}</div>
                  <div className={styles.editable_action}><i className="far fa-pen"></i></div>
                </div>
              </div>
            </div>

            {/* <div className={styles.details_block}>
              <div className={styles.db_content}>
                <div className={styles.editable}>
                  <div className={styles.editable_value}>{payMethod}</div>
                  <div className={styles.editable_action}></div>
                </div>
              </div>
            </div> */}

            <div className={styles.details_block}>
              {/* <div className={styles.db_label}>Итог</div> */}
              <div className={styles.db_content}>
                <div className={styles.sflexed}>
                  <div className={styles.details_sum}><b>Сумма:</b> <span>{fixNum(getTotalSum())} <RubbleIcon /></span></div>
                  {!deliveryOwn && getTotalSum() < 5000 ? <div className={styles.details_sale}><b>Доставка:</b> <span>500 <RubbleIcon /></span></div> : <></>}
                  <div className={styles.details_sale}><b>Скидки:</b> <span>{fixNum(getTotalSale())} <RubbleIcon /></span></div>
                  {getPromoTotal() != 0 ? <div className={styles.details_sale}><b>Промо:</b> <span>{getPromoTotal()} <RubbleIcon /></span></div> : <></>}
                </div>
                <div className={styles.details_total}><b>К оплате</b> <span>{fixNum(getTotal())} <RubbleIcon /></span></div>
              </div>
            </div>

            {/* <p className={styles.pay_method}>{deliveryOwn ? <>Заказ оплачивается<br/>при получении в магазине</> : "Заказ оплачивается курьеру картой или наличными при получении"}</p> */}

            <Radio.Group style={{width: "100%"}} onChange={(ev) => setPayMethodP(ev.target.value)} value={payMethodP}>
              <Space direction="horizontal" align="center" style={{width: "100%", marginBottom: "24px"}}>
                <Radio value="qr">QR код</Radio>
                <Radio value="card">Картой</Radio>
                <Radio value="cash">Наличными</Radio>
              </Space>
            </Radio.Group>

            <div className={styles.details_buy}>
              <button onClick={() => createOrder()}>Оформить заказ</button>
            </div>
          </div>
        </div>
      </div>

      <OptionsModal
        openned={isAccountVisible}
        title="Ваши данные"
        height="auto"
        onClose={() => setIsAccountVisible(false)}
        done="Продолжить"
        onDone={() => {
          createAccountAndOrder()
        }}>
          <div className="xform">
            <div className="xfield">
              <label htmlFor="client_name">Ваше имя</label>
              <input id="client_name" name="client_name" type="text" placeholder="Иван Иванов" value={clientName} onChange={(ev) => setClientName(ev.target.value)} />
            </div>
            <div className="xfield">
              <label htmlFor="client_phone">Ваш телефон</label>
              {/* <input id="client_phone" name="client_phone" type="text" placeholder="Иван Иванов" value={clientPhone} onChange={(ev) => setClientPhone(ev.target.value)} /> */}
              <InputMask id="client_phone" name="client_phone" mask="+7 999 999 99 99" maskChar="_" placeholder="+7 999 999-99-99" value={clientPhone} onChange={(event) => setClientPhone(event.target.value)} />
            </div>
            <div className="xfield">
              <label htmlFor="client_email">Ваш Email</label>
              <input id="client_email" name="client_email" type="text" placeholder="example@gmail.com" value={clientEmail} onChange={(ev) => setClientEmail(ev.target.value)} />
            </div>
          </div>
      </OptionsModal>

      <OptionsModal
        openned={isCommentVisible}
        title="Комментарий"
        height="auto"
        onClose={() => setIsCommentVisible(false)}
        done="Сохранить"
        onDone={() => {
          setIsCommentVisible(false)
        }}>
        <div className="xfield">
          <label htmlFor="promocode">Введите комментарий к заказу</label>
          <textarea rows={4} id="promocode" name="promocode" placeholder="Текст..." value={deliveryComment} onChange={(ev) => setDeliveryComment(ev.target.value)}></textarea>
        </div>
      </OptionsModal>

      <OptionsModal
        openned={isAddressVisible}
        title={deliveryOwn ? "Выберите магазин" : "Введите адрес"}
        height="auto"
        onClose={() => setIsAddressVisible(false)}
        done="Сохранить"
        onDone={() => {
          setDeliveryAddress(`г. ${shopStore.selectedCityName}, ${deliveryStreet}, д. ${deliveryHouse}, подъезд ${deliveryEntrance}, этаж ${deliveryFloor}, кв. ${deliveryRoom}, комментарий: ${deliveryKey}`)

          setIsAddressVisible(false)
        }}>
        {deliveryOwn ? <div className="cities_selecting">
          {shopStore.selectedCity != null ? shopStore.selectedCity.shops.filter(v => v.status == 1).map(shop => <div className={"cs_item" + (deliveryShop == shop.address ? " selected" : "")} onClick={() => {
              setDeliveryShop(shop.address)
            }}>
            <div className="cs_item__name">{shop.name}</div>
            <div className="cs_item__check"><span><i className="fas fa-check"></i></span></div>
          </div>) : <></>}
        </div> : <>
          <div className="xform">
            <p className="xform_desc">Вы оформляете заказ в городе - <b>«{shopStore.selectedCityName}»</b></p>
            <div className="xflexed">
              <div className="xfield">
                <label htmlFor="addr_street">Улица</label>
                <input id="addr_street" name="addr_street" type="text" placeholder="Ленина" value={deliveryStreet} onChange={(ev) => setDeliveryStreet(ev.target.value)} />
              </div>
              <div className="xfield sm">
                <label htmlFor="addr_house">Дом</label>
                <input id="addr_house" name="addr_house" type="text" placeholder="1" value={deliveryHouse} onChange={(ev) => setDeliveryHouse(ev.target.value)} />
              </div>
            </div>
            <div className="xflexed">
              <div className="xfield">
                <label htmlFor="addr_entre">Подъезд</label>
                <input id="addr_entre" name="addr_entre" type="text" placeholder="1" value={deliveryEntrance} onChange={(ev) => setDeliveryEntrance(ev.target.value)} />
              </div>
              <div className="xfield">
                <label htmlFor="addr_floor">Этаж</label>
                <input id="addr_floor" name="addr_floor" type="text" placeholder="1" value={deliveryFloor} onChange={(ev) => setDeliveryFloor(ev.target.value)} />
              </div>
              <div className="xfield">
                <label htmlFor="addr_room">Квартира</label>
                <input id="addr_room" name="addr_room" type="text" placeholder="1" value={deliveryRoom} onChange={(ev) => setDeliveryRoom(ev.target.value)} />
              </div>
            </div>
            <div className="xflexed">
            </div>
            <div className="xfield">
              <label htmlFor="addr_key">Комментарий</label>
              <textarea id="addr_key" name="addr_key" rows={3} placeholder="К примеру - код от подъезда" value={deliveryKey} onChange={(ev) => setDeliveryKey(ev.target.value)}></textarea>
            </div>
          </div>
        </>}
      </OptionsModal>

      <OptionsModal
        openned={isPromoVisible}
        title="Промокод"
        height="auto"
        onClose={() => setIsPromoVisible(false)}
        done="Применить"
        doneLoading={isPromoLoading}
        onDone={async () => {
          if (shopStore.promocode == "") {
            setIsPromoLoading(false)
            message.error("Введите промокод!")
            return
          }

          if (isPromoLoading) {
            return
          }

          setIsPromoLoading(true)
          const res = await ApiService.store.checkPromocode(shopStore.promocode)
          if (res.data.state == 1) {
            setIsPromoLoading(false)

            const promocode: any = res.data.promocode

            if (promocode.by_price != 0) {
              let sum = 0
              shopStore.goods.forEach(good => {
                sum += (good.amount * (getFinalPrice(good.product) - ((getFinalPrice(good.product) / 100) * good.product.sale)))
              })
              if (sum < promocode.by_price) {
                message.warning(`Cумма заказа должна быть больше ${promocode.by_price} руб.!`)
                return
              }
            }

            if (promocode.first_order == true) {
              if (shopStore.orders.length != 0) {
                message.warning(`Промокод применим только к первому заказу!`)
                return
              }
            }

            if (promocode.multiuse == false) {
              const exists = shopStore.orders.findIndex(v => v.promocode == shopStore.promocode)
              if (exists != -1) {
                message.warning(`Этот промокод одноразовый и Вы его уже использовали!`)
                return
              }
            }

            setIsPromoVisible(false)
            shopStore.setSelectedPromocode(promocode)
            message.success("Промокод успешно применен!")
          } else {
            setIsPromoLoading(false)
            message.error(res.data.message)
          }
        }}>
        <div className="xfield">
          <label htmlFor="promocode">Введите промокод</label>
          <input type="text" placeholder="XXXXXXXXX" value={shopStore.promocode} onChange={(ev) => shopStore.setPromocode(ev.target.value)} />
        </div>
      </OptionsModal>

      <OptionsModal
        openned={isShopVisible}
        title="Выберите магазин"
        height="auto"
        onClose={() => setIsShopVisible(false)}
        done="Выбрать"
        onDone={() => {}}>

      </OptionsModal>
    </>
  )
}

export default observer(BasketView)