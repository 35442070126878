import {API_URL, ControllerName} from "../utils/defaults";
import $api from "./http";

export class ApiService {

  constructor() {}

  static users = {
    checkAccount: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/check`), dto)
    },
    signInWithCode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/signin`), dto)
    },
    signUpWithCode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/signup`), dto)
    },

    lostPassword: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `lost/sendcode`), dto)
    },

    signUpThreat: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `auth/threat`), dto)
    },

    signIn: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin`), dto)
    },
    signInWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin/google`), {token: jwtToken})
    },
    reauthWithGoogle: async (jwtToken: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `reauth/google`), {token: jwtToken});
    },
    updateAccount: async (dto: any, userId: number) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account/${userId}`), dto)
    },
    getAccount: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account`))
    },

    getUsers: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams(query)}`))
    },
    getAccountById: async (userId: number) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account/byid/${userId}`))
    }
  }

  static store = {
    getStats: async () => {
      return $api.get(this.buildUrl(ControllerName.STORE, `stats`))
    },
    getProductByLink: async (link_name: string) => {
      return $api.get(this.buildUrl(ControllerName.STORE, `list/by_link/${link_name}`))
    },
    getProduct: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `list/by_id/${id}`))
    },
    getProductsBests: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `bests`))
    },
    getProducts: async (params: {
      search: string,
      sort: string,
      sortBy: string,
      category: string,
      page: number,
      limit: number,
      only_active?: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `list${this.buildParams(params)}`))
    },
    createProduct: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `list`), dto)
    },
    updateProduct: async (pid: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `list/${pid}`), dto)
    },
    deleteProduct: async (pid: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `list/${pid}`))
    },
    likeProduct: async (pid: number) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `list/like/${pid}`), {})
    },
    rateProduct: async (pid: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `list/rate/${pid}`), dto)
    },
    unrateProduct: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `list/rate/${id}`))
    },

    getBasket: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `basket`))
    },
    addToBasket: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `basket`), dto)
    },
    updateBasket: async (dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `basket`), dto)
    },
    trashBasket: async () => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `basket`))
    },

    getCats: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `cats`))
    },
    getCategory: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `cats/${id}`))
    },
    createCategory: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `cats`), dto)
    },
    updateCategory: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `cats/${id}`), dto)
    },
    deleteCategory: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `cats/${id}`))
    },
    addProductCat: async (id: number, pid: number) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `cats/${id}/${pid}`), {})
    },
    deleteProductCat: async (id: number, pid: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `cats/${id}/${pid}`))
    },

    getTimezones: async (status: number) => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `timezones${this.buildParams({status})}`))
    },
    createTimezone: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `timezones`), dto)
    },
    updateTimezone: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `timezones/${id}`), dto)
    },
    deleteTimezone: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `timezones/${id}`))
    },

    getPromocodes: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `promocodes`))
    },
    getMyPromocodes: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `promocodes_my`))
    },
    checkPromocode: async (code: string) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `promocode_check/${code}`), {})
    },
    createPromocode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `promocodes`), dto)
    },
    updatePromocode: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `promocodes/${id}`), dto)
    },
    deletePromocode: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `promocodes/${id}`))
    },

    getCities: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `cities`))
    },
    createCity: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `cities`), dto)
    },
    updateCity: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `cities/${id}`), dto)
    },
    deleteCity: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `cities/${id}`))
    },

    getShops: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `shops`))
    },
    createShop: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `shops`), dto)
    },
    updateShop: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `shops/${id}`), dto)
    },
    deleteShop: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.STORE, `shops/${id}`))
    },

    getAllOrders: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `all_orders${this.buildParams(query)}`))
    },
    getOrders: async () => {
      return await $api.get(this.buildUrl(ControllerName.STORE, `orders`))
    },
    createOrder: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `orders`), dto)
    },
    updateOrder: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.STORE, `orders/${id}`), dto)
    },

    addToStock: async (id: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `stock/${id}/add`), dto)
    },
    writeFromStock: async (id: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.STORE, `stock/${id}/write`), dto)
    }
  }

  static brands = {
    getBrands: async () => {
      return await $api.get(this.buildUrl(ControllerName.BRANDS, `list`))
    },
    getBrand: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.BRANDS, `list/${id}`))
    },
    getBrandByLink: async (link: string) => {
      return await $api.get(this.buildUrl(ControllerName.BRANDS, `bylink/${link}`))
    },
    createBrand: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BRANDS, `list`), dto)
    },
    updateBrand: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BRANDS, `list/${id}`), dto)
    },
    deleteBrand: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BRANDS, `list/${id}`))
    }
  }

  static blog = {
    getPosts: async (query: {
      search: string
      tag: string
      page: number
      limit: number
      order: string
      orderBy: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list${this.buildParams(query)}`))
    },
    getPostByLink: async (link_name: string) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list/${link_name}/bylink`))
    },
    getPost: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list/${id}`))
    },
    createPost: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list`), dto)
    },
    updatePost: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `list/${id}`), dto)
    },
    deletePost: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}`))
    },

    likePost: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/like`), {})
    },
    lookPost: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/look`), {})
    },
    commentPost: async (id: number, dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/comment`), dto)
    },
    updateComment: async (id: number, cid: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `list/${id}/comment/${cid}`), dto)
    },
    deleteComment: async (id: number, cid: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}/comment/${cid}`))
    },
    addPostTag: async (id: number, tag_id: number) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list/${id}/tag/${tag_id}`), {})
    },
    deletePostTag: async (id: number, tag_id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}/tag/${tag_id}`), {})
    },
    getTags: async () => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `tags`))
    },
    createTag: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `tags`), dto)
    },
    updateTag: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `tags/${id}`), dto)
    },
    deleteTag: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `tags/${id}`))
    },
  }

  static crm = {
    updateTicket: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CRM, `crm/ticket/${id}`), dto)
    } 
  }

  static core = {
    createAmoTicket: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CORE, `amo_ticket`), dto)
    },
    createMail: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CORE, `mail`), dto)
    },
    getSettings: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `settings`))
    },
    updateSetting: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CORE, `settings/${id}`), dto)
    },
    getImageRes: async (filename: string) => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `image/${filename}`))
    }
  }

  static versions = {
    getVersions: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions`));
    },
    getLatestVersion: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `versions/latest`));
    },
    createVersion: async () => {},
    updateVersion: async () => {},
    deleteVersion: async () => {}
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }
}